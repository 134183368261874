import { Component } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { SeriesService } from "../model/series.service";
import { SeriesInfo } from "../model/series-api";

@Component({
    selector: 'app-series-details-info',
    templateUrl: './series-details-info.component.html',
    styleUrl: './series-details-info.component.scss',
    standalone: false
})
export class SeriesDetailsInfoComponent {

  reloading: boolean = false
  series: SeriesInfo | undefined

  constructor(
    private service: SeriesService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => this.handleUrlParamsChanged(params))
  }

  private handleUrlParamsChanged(params: Params) {
    let seriesId = params.series ?? ''
    this.reloading = true
    this.service.getSeries(seriesId).subscribe(d => this.handleData(d))
  }

  private handleData(d: SeriesInfo) {
    this.series = d
    this.reloading = false
  }
}
