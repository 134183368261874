import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NavLink } from "../../product/product-details-tabs/nav-link";
import { ActivatedRoute, Routes } from "@angular/router";
import { AuthService } from "../../../auth/auth.service";
import { SeriesInfo } from "../model/series-api";
import { SeriesDetailsTabRouteData } from "./series-details-tab-route-data";

@Component({
    selector: 'app-series-details-tabs',
    templateUrl: './series-details-tabs.component.html',
    styleUrl: './series-details-tabs.component.scss',
    standalone: false
})
export class SeriesDetailsTabsComponent {
  @Input()
  set data(series: SeriesInfo | undefined) {
    this.series = series
    if (this.series) {
      this.updateNavigation()
    }
  }

  series: SeriesInfo | undefined
  isViewInitialized = false
  navLinks: NavLink[] = []

  constructor(private route: ActivatedRoute, private changeDetector: ChangeDetectorRef, private authService: AuthService) {
  }


  ngOnInit() {
    this.updateNavigation()
  }

  ngAfterViewInit() {
    this.isViewInitialized = true
    this.changeDetector.detectChanges()
  }

  private updateNavigation() {
    let config = this.route.routeConfig
    let children = config?.children
    this.navLinks = children ? this.buildNavItems(children) : []
  }


  private buildNavItems(routes: Routes): NavLink[] {
    return (routes)
      .filter(route => route.data && this.isVisible(route.data as SeriesDetailsTabRouteData))
      .map(({ path = '', data }) => ({
        path: path,
        label: data?.label ?? '',
        icon: data?.icon
      }));
  }

  private isVisible(data: SeriesDetailsTabRouteData): boolean {
    if (!this.containsData(data.label)) return false
    return this.hasPermission(data)
  }

  private hasPermission(data: SeriesDetailsTabRouteData): boolean {
    if (!data.permissions.length) return true
    let hasAccess = false
    data.permissions.forEach(permission => {
      if (this.authService.hasRole(permission)) hasAccess = true
    })
    return hasAccess
  }

  private containsData(label: string): boolean {
    switch (label) {
      case "Documents": {
        if (!this.series?.documents) return false
        break;
      }
    }
    return !!(this.series)
  }
}
