import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { SeriesSearchResponse, SeriesSearchResultEntry } from "../../search/model/search-api";

@Component({
    selector: 'app-series-board-content-result-list',
    templateUrl: './series-board-content-result-list.component.html',
    styleUrl: './series-board-content-result-list.component.scss',
    standalone: false
})
export class SeriesBoardContentResultListComponent {

  @Input()
  set response(response: SeriesSearchResponse | null) {
    this.entries = response ? response.entries.content : []
    this.pageSize = response ? response.entries.pageable.size : 25
    this.pageIndex = response ? response.entries.pageable.number : 0
    this.totalSize = response ? response.entries.totalSize : 0

  }

  @Output() page = new EventEmitter<PageEvent>()

  entries: SeriesSearchResultEntry[] = []
  pageSize: number = 25
  pageIndex: number = 0
  totalSize: number = -1

  handlePaginatorEvent(event: PageEvent) {
    this.page.emit(event)
  }

  constructor(public router: Router) {
  }
  showDetails(entry: SeriesSearchResultEntry) {
    this.router.navigate(['portal', 'series', 'details', 'information'], { queryParams: { series: entry.id } }).then()
  }

  showDocuments(entry: SeriesSearchResultEntry) {
    this.router.navigate(['portal', 'series', 'details', 'documents'], { queryParams: { series: entry.id } }).then()
  }

}
