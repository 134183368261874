import { Component } from '@angular/core';
import { SeriesService } from "../model/series.service";
import { ActivatedRoute, Params } from "@angular/router";
import { SeriesInfo } from "../model/series-api";
import { DocumentService } from "../../document/model/document.service";
import { Document } from "../../document/model/document-api";
import { HttpResponse } from "@angular/common/http";

@Component({
    selector: 'app-series-details-documents',
    templateUrl: './series-details-documents.component.html',
    styleUrl: './series-details-documents.component.scss',
    standalone: false
})
export class SeriesDetailsDocumentsComponent {
  reloading: boolean = false
  documents: Document[] = []

  constructor(
    private service: SeriesService,
    private documentService: DocumentService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => this.handleUrlParamsChanged(params))
  }

  private handleUrlParamsChanged(params: Params) {
    let seriesId = params.series ?? ''
    this.reloading = true
    this.service.getSeries(seriesId).subscribe(d => this.handleData(d))
  }

  private handleData(d: SeriesInfo) {
    this.documentService.getDocumentsByIds(d.documents.documents).subscribe(d => this.handleDocuments(d))
  }

  private handleDocuments(response: HttpResponse<Document[]>) {
    this.documents = response.body ?? []
    this.reloading = false
  }
}
